<template>
    <!-- Popup -->
    <div class="popup">
      <div class="popup-content rate_break_down">
        <!-- <span @click="showPopup = false" class="close">&times;</span> -->
        <i class="closeme adj1" @click="closeModal">X</i>
        <h2>Rate Breakdown Details</h2>
        <div class="rate_break_down_row row">
          <div class="col">
            <div>Courier Charge (Except GST)</div>
            <div>Handling Fee ({{ handling_fee_percentage }}%)</div>
          </div>
          <div class="col">
            <div>$ {{ price_without_GST }}</div>
            <div>$ {{ handling_fee }}</div>
          </div>
        </div>
        <div class="rate_break_down_row row">
          <div class="col">
            <div>Total Price</div>
            <div>GST Value</div>
          </div>
          <div class="col">
            <div>$ {{ total_price }}</div>
            <div>$ {{ gst_value }}</div>
          </div>
        </div>
        <div class="rate_break_down_row row">
          <div class="col">
            <div>Total Price with GST</div>
          </div>
          <div class="col">
            <div>$ {{ total_price_with_gst }}</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "PriceBreakdownPopupModal",
  
    data() {
      return {
      };
    },
    created() {
    },
    methods: {
      closeModal() {
        this.$emit("closeModal");
      },
    },
    props: ["handling_fee_percentage", "price_without_GST", "handling_fee", "total_price", "gst_value", "total_price_with_gst"]
  };
  </script>
  <style scoped>
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
  }
  
  .popup-content {
    padding: 50px;
    border-radius: 5px;
    width: 100%;
    height: auto;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
  }
  </style>
  